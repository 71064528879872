//iUbenda

#iubenda_policy.iubenda_fluid_policy .iub_container{
	margin: 2rem 0 0 !important;
}

#iubenda_policy .iub_content{
	padding: 0 !important;
	font-family: $oswald !important;
}

#iubenda_policy h1, #iubenda_policy h2, #iubenda_policy h3, #iubenda_policy h4, #iubenda_policy h5, #iubenda_policy h6{
	color: $black !important;
}

#iubenda_policy a{
	color: $light-grey !important;
}

#iubenda_policy .iub_content .iub_footer{
	padding: 24px 0 0 !important;
}