/*Sidebar*/

#ba-header-wrapper{
	width: 220px;
	height: 100vh;
	position: fixed;
	background: $black;
	border-right: 1px solid $white;
	font-family: $oswald;
	text-align: center;
	color: $white;
	text-transform: uppercase;
}

#ba-header{
	width: 100%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

#ba-header div{
	margin: 2rem 0;
}

#ba-header-brand img{
	width: auto;
	height: 100px;
}

#ba-header-nav{
	margin: 2rem 0 calc(2rem - 5px) !important;
}

#ba-header-nav .nav{
	display: block;
}

#ba-header-nav .nav li{
	display: block;
}

#ba-header-nav .nav li a{
	color: $white;
}

#ba-header-nav .nav li a:hover{
	color: $light-grey;
}

#ba-header-social{
	line-height: 1;
	font-size: 1.2rem;
	margin: calc(2rem - 5px) 0 2rem !important;
}

#ba-header-social a{
	color: $white;
}

#ba-header-social a:hover{
	color: $light-grey;
}

#ba-header-social i{
	margin: 0 2px;
}

#ba-header-copyright{
	font-size: 12px;
	line-height: 1.2;
}

#ba-header-privacy{
	font-size: 12px;
	line-height: 1;
}

#ba-header-privacy a{
	color: $white;
}

#ba-header-privacy a:hover{
	color: $light-grey;
}