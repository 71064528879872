.ba-page-content p{
	text-transform: uppercase;
}

.ba-page-content p:first-child{
	font-size: 1.5rem;
	color: $black;
}

.ba-page-content a{
	color: $light-grey;
}

//Add This

.ba-share{
	margin: calc(2rem + 1px) 0 0;
	padding: 2rem 0 0;
	border-top: $border;
}

.page-id-13 .ba-share{
	margin: calc(2rem - 14px) 0 0;
}

.page-id-15 .ba-share{
	margin: calc(2rem - 6.5px) 0 0;
}

.page-id-19 .ba-share{
	margin: calc(2rem - 15px) 0 0;
}

.at-resp-share-element .at-share-btn{
	margin-bottom: 0 !important;
}

.at-resp-share-element .at-share-btn:first-child{
	margin-left: 0;
}