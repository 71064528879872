.ba-shows-single{
	margin: 2rem 0 0;
	padding-bottom: calc(2rem + 5px);
	border-bottom: $border;
}

.ba-shows-single:last-child {
    border-bottom: none;
    padding: 0;
}

.ba-shows-single h3{
	margin: 0 0 0.5rem;
}

.ba-shows-single p{
	color: $black;
}

.ba-shows-single p:last-child{
	margin: 0;
}

.ba-shows-single p a{
	color: $grey;
}

.ba-shows-single p a:hover{
	color: $light-grey;
}

.ba-shows-single-date{
	margin: 0 0 0.5rem;
	font-size: 1.125rem;
	text-transform: uppercase;
}

.ba-shows-single-button{
	background: $black;
	padding: 0.5rem;
	color: $white;
	display: inline-block;
}

.ba-shows-single-button:hover{
	opacity: 1;
	background: $grey;
	color: $white;
}

.ba-shows-single-button i{
	margin: 0 0.25rem 0 0;
}

.shows .ba-share{
	margin: calc(2rem + 5px) 0 0;
}