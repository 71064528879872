#ba-footer{
	padding: 2rem;
	text-align: center;
	background: $black;
	color: $white;
	font-family: $oswald;
	text-transform: uppercase;
}

#ba-footer-social{
	line-height: 1;
	font-size: 1.2rem;
	margin: 0 0 1rem;
}

#ba-footer-social a{
	color: $white;
}

#ba-footer-social a:hover{
	color: $light-grey;
}

#ba-footer-social i{
	margin: 0 2px;
}

#ba-footer-copyright{
	font-size: 12px;
	line-height: 1.2;
}

#ba-footer-privacy{
	font-size: 12px;
	line-height: 1;
	margin: 0 0 1rem;
}

#ba-footer-privacy a{
	color: $white;
}

#ba-footer-privacy a:hover{
	color: $light-grey;
}