.ba-gallery-single{
	padding-bottom: calc(2rem - 2.5px);
    border-bottom: $border;
}

.ba-gallery-single:last-child{
	padding: 0;
	border: none;
}

.ba-gallery-single h2{
	margin-bottom: 7.5px;
}

.ba-gallery-row{
	padding: 0 7.5px;
}

.ba-gallery-col{
	padding: 0 7.5px;
	margin: 7.5px 0;
}

.ba-gallery-thumbnail{
	position: relative;
}

.ba-gallery-thumbnail-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: .25s ease;
	background: rgba(0,0,0,0.6);
	color: $white;
	text-align: center;
}

.ba-gallery-thumbnail-overlay i{
    color: $white;
    font-size: 24px;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);	
}

.ba-gallery-thumbnail:hover .ba-gallery-thumbnail-overlay {
	opacity: 1;
}