.ba-embed-container { 
	position: relative; 
	padding-bottom: 50%;
	height: 0;
	overflow: hidden;
	max-width: 50%;
	height: auto;
} 

.ba-embed-container iframe,
.ba-embed-container object,
.ba-embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.ba-music-releases-single{
	padding-bottom: 2rem;
	border-bottom: $border;
}

.ba-music-releases-single:last-child{
	border-bottom: none;
	padding: 0;
}

.ba-music-releases-single-reviews{
	margin: 2rem 0 0;
	padding: 0;
	list-style: none;
}

.ba-music-releases-single a{
	color: $light-grey;
}

.ba-music-releases-single a:hover{
	opacity: 0.75;
}

.ba-music-releases-single-reviews li{
	margin: 0 0 1rem;
}

.ba-music-releases-single-reviews li:last-child{
	margin: 0;
}

.ba-music-releases-single-reviews li p{
	margin: 0;
	text-transform: uppercase;
}

.ba-music-releases-single-reviews li p:before{
	content: '"';
}

.ba-music-releases-single-reviews li p:after{
	content: '"';
}

.ba-music-releases-single-reviews li span{
	color: $light-grey;
	text-transform: uppercase;
}

.ba-release-links{
	margin: 2rem 0 0;
}

.ba-release-links ul{
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 0;
}

.ba-release-links ul li{
	line-height: 1;
	display: inline-block;
	font-size: 1rem;
	margin: 0 0.5rem 0 0;
}

.ba-release-links ul li:last-child{
	margin: 0;
}

.ba-release-links ul li a{
	background: $black;
	padding: 0.5rem;
	color: $white;
}

.ba-release-links ul li a:hover{
	opacity: 1;
	background: $grey;
}

.ba-release-links ul li a i{
	margin: 0 0.25rem 0 0;
}

.ba-release-information{
	margin: calc(2rem + 2.5px) 0 0;
}

.ba-release-information p{
	font-size: 12px;
	text-transform: uppercase;
}

.ba-release-information p:last-child{
	margin: 0;
}

.ba-release-information p a{
	color: $light-grey;
}

.ba-release-information p a:hover{
	opacity: 0.75;
}