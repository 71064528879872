//Font Face

@font-face {
    font-family: 'oswald_stencilbold';
    src: url('../fonts/oswald-stencil-webfont.woff2') format('woff2'),
         url('../fonts/oswald-stencil-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colours
$black:					black;
$white:					white;
$grey:					#666666;
$light-grey:			#999999;
$border-grey:			#c5c5c5;
$ghost-grey:			#f5f5f5;

// Borders
$border:				1px dashed #c5c5c5;

//Fonts

$oswald:				'Oswald', sans-serif;
$oswald-stencil:		'oswald_stencilbold', 'Oswald', sans-serif;
