@media (min-width: 993px) {
	#ba-mobile-nav{
		display: none;
	}
	
	#ba-header-nav-toggle{
		display: none;
	}
	
	#ba-footer{
		display: none;
	}
}

@media (max-width: 992px) {
	
	//Header
	
	#ba-header-wrapper{
		width: 100%;
		height: auto;
		position: static;
		border: none;
		text-align: left;
	}
	
	#ba-header{
		position: static;
		transform: none;
		top: inherit;
		padding: 15px;
		border-bottom: 1px solid $ghost-grey;
	}
	
	#ba-header div{
		margin: 0;
	}
	
	#ba-header-brand{
		float: left;
	}
	
	#ba-header-brand img{
		height: 50px;
	}
	
	#ba-header-nav{
		display: none;
	}
	
	#ba-header-social, #ba-header-copyright, #ba-header-privacy{
		display: none;
	}
	
	#ba-carousel-wrapper, #ba-latest-release-wrapper{
		margin: 0;
	}
	
	//Mobile Nav
	
	#ba-mobile-nav{
		text-align: center;
	}
	
	#ba-mobile-nav .nav li{
		border-bottom: 1px solid $white;
		font-size: 1rem;	
	}
	
	#ba-mobile-nav .nav li:last-child{
		border: none;
	}
	
	#ba-mobile-nav .nav li a{
		color: $white;
		display: block;
		line-height: 1;
		padding: 10px 0;
	}
	
	#ba-mobile-nav .nav li a:hover{
		color: $light-grey;
	}
	
	//Mobile Nav Toggle
	
	#ba-header-nav-toggle{
		float: right;
		font-size: 1.5rem;
		line-height: 47px;
	}
	
	#ba-header-nav-toggle a{
		color: $white;
	}
	
	#ba-header-nav-toggle a:hover{
		color: $light-grey;
	}
	
	//Wrapper
	
	.ba-wrapper{
		margin: 0;
	}
	
	//Contact
	
	input, textarea{
		width: 100%;
	}	
}

@media (max-width: 480px) {
	
	//General
	
	html{
		background: $black;
	}
	
	html, body{
		font-size: 14px;
	}
	
	.ba-col{
		margin: 7.5px 0;
	}
	
	//Wrapper
	
	.ba-wrapper{
		padding: 2rem 15px;
	}
	
	.ba-wrapper-front-page{
		padding: 2rem 15px 0;
	}
	
	//Pages
	
	#ba-latest-release-wrapper{
		padding: 4rem 15px;
	}
	
	.ba-page-content p:last-child{
		margin-bottom: 0;
	}
	
	.ba-band-members h2, .ba-gallery-single h2{
		margin-bottom: 7.5px;
	}
	
	.page-id-13 .ba-share, .page-id-15 .ba-share {
	    margin: calc(2rem - 6.5px) 0 0;
	}
	
	.ba-gallery-single{
		padding-bottom: calc(2rem - 4px);
	}
	
	.ba-embed-container{
		max-width: 100%;
	}
	
	.ba-band-member-content{
		padding: 1.5rem;
	}
}

@media (max-width: 320px) {
	.ba-band-member-content{
		padding: 15px;
	}
	
	.ba-band-member-content p{
		font-size: 12px;
	}
}