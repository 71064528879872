.ba-band-members h2{
	margin-bottom: 0;
}

.ba-band-member{
	position: relative;
}

.ba-band-member-content {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	opacity: 0;
	transition: .25s ease;
	background: rgba(0,0,0,0.75);
	color: $white;
	padding: 2rem;
}

.ba-band-member-content h3{
	margin: 0;
}

.ba-band-member-content h5{
	color: $light-grey;
}

.ba-band-member-content p{
	color: $border-grey;
}

.ba-band-member:hover .ba-band-member-content {
	opacity: 1;
}