//Carousel

#ba-carousel-wrapper{
	margin: 0 0 0 220px;
}

.ba-carousel-item{
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.ba-carousel-item img{
	width: 100%;
	height: auto;
	max-height: 70vh;
}

.owl-theme .owl-dots{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0 0 2rem !important;
	line-height: 1;
}

.owl-theme .owl-dots .owl-dot span{
	margin: 0 0.25rem;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
	background: $white;
}

.owl-theme .owl-dots .owl-dot span{
	background: $light-grey;
	width: 1rem;
	height: 1rem;
}

//Wrapper

.ba-wrapper-front-page{
	text-align: center;
	padding: 4rem 4rem 0;
	background: $ghost-grey;
}

.ba-wrapper-front-page img{
	margin: 2rem 0 0;
}

//Content

.ba-front-page-content p{
	font-family: $oswald-stencil;
	color: $black;
	font-size: 2rem;
}

.ba-front-page-content p:last-child{
	margin-bottom: 0;
}

#ba-latest-release-wrapper{
	margin: 0 0 0 220px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 4rem;
	text-align: center;
	font-family: $oswald;
}

.ba-latest-release{
	max-width: 800px;
	margin: 0 auto;
}

.ba-latest-release h1{
	margin: 0 0 1rem;
	color: $white;	
}

.ba-latest-release p{
	color: $white;
	text-transform: uppercase;
	margin: 0 auto 2rem;
	max-width: 600px;
}

.ba-latest-release a{
	color: $white;
	text-transform: uppercase;
	display: block;
	margin: 4rem 0 0;
	line-height: 1;
}

.ba-latest-release a:hover{
	opacity: 0.8;
}

.ba-latest-release a i{
	margin-right: 0.5rem;
}

.ba-latest-release .ba-embed-container { 
	position: relative; 
	padding-bottom: 50%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;
	border: none;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
} 

.ba-latest-release .ba-embed-container iframe,
.ba-latest-release .ba-embed-container object,
.ba-latest-release .ba-embed-container embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}