//Enquiries

.ba-contact-enquiries strong{
	color: $black;
	font-family: $oswald-stencil;
	text-transform: uppercase;
}

.ba-contact-enquiries a{
	color: $grey;
}

.ba-contact-enquiries a:hover{
	color: $light-grey;
}

//Enquiry Form

.ba-contact-form-container{
	margin: 2rem 0 0;
}

label{
	width: 100%;
	color: $black;
	text-transform: uppercase;
	font-family: $oswald-stencil;
	margin: 0;
}

input{
	padding: 0.5rem;
	color: $grey;
	margin: 0.25rem 0 0;
	font-family: $oswald;
	width: 50%;
	border: $border;
}

textarea{
	padding: 0.5rem;
	color: $grey;
	font-family: $oswald;
	width: 50%;
	border: $border;
}

input[type=submit]{
	width: 20%;
	background: $black;
	color: $white;
	text-transform: uppercase;
	cursor: pointer;
	transition: all ease 0.25s;
	border: none;
}

input[type=submit]:hover{
	background: $grey;
	transition: all ease 0.25s;
}

input[type=checkbox], input[type=radio]{
	width: auto;
	margin: 0;
}

span.wpcf7-list-item{
	margin: 0;
}

select{
	height: 38px;
	width: 50%;
}

div.wpcf7-response-output{
	margin: 2rem 0 0;
}

span.wpcf7-not-valid-tip{
	margin: 0.5rem 0 0;
	text-transform: uppercase;
	font-family: $oswald-stencil;
}

.page-id-19 div.wpcf7-response-output{
	margin: 2rem 0 1rem;
}