//General

body{
	color: $black;
}

p{
	color: $grey;
	line-height: 1.25;
}

a{
	text-decoration: none;
	transition: ease all 0.25s;
}

a:hover{
	text-decoration: none;
	transition: ease all 0.25s;
}

img{
	width: 100%;
	height: auto;
	display: block;
}

.page{
	background: $ghost-grey;
}

.ba-col{
	margin: 15px 0;
}

//Fonts

h1, h2, h3, h4, h5, h6{
	font-family: $oswald-stencil;
}

h2, h3{
	margin: 2rem 0 1rem;
}

//WOW

.wow {
  visibility: hidden;
}